import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Button, CircularProgress,   FormControl,
  InputLabel,
  MenuItem,
  Select } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { Add as AddIcon } from "@material-ui/icons";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import { API } from "aws-amplify";
import { querystring } from "../../libs/querystring" 

// styles
import useStyles from "./styles";

export default function Create(props) {
  const classes = useStyles();

  //state
  const [storeNum, setStoreNum] = useState('');
  const [storeNumErr, setStoreNumErr] = useState(false);
  const [date, setDate] = useState(new Date(Date.now()));
  const [dateErr, setDateErr] = useState(false);
  const [hauler, setHauler] = useState('');
  const [haulerErr, setHaulerErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [items, setItems] = useState([{count: '', price: '', note: '', countErr: false, priceErr: false}])

  useEffect(() => {
    const store_num = querystring("store");
    if (store_num) setStoreNum(store_num);
  }, []);

  // TODO: put in DDB
  const haulers = [
    "Ben Tire",
    "Black to Green",
    "Emanuel",
    "Best Haulers",
    "Liberty",
    "Liberty Lakin",
    "tyrex"
  ];

  function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  function isValidFloat(sNum) {
    var pattern = /^\d+(\.\d{1,2})?$/;
    return pattern.test(sNum);
  }

  function handleCreate() {
    // reset form errors
    setDateErr(false);
    setStoreNumErr(false);
    setHaulerErr(false);
    items.forEach((item, idx) => {
      items[idx].countErr = false;
      items[idx].priceErr = false;
      setItems([...items]);
    });

    // check hauler (if admin)
    let haulerData;
    if (props.cognito.role === 'admin') {
      if (!hauler) {
        setHaulerErr(true);
        return;
      }
      haulerData = hauler;
    } else {
      haulerData = props.cognito.role_details;
    }

    // check store num
    if (!storeNum) {
      setStoreNumErr(true);
      return;
    }

    // get date
    if (!date || date.toString() === 'Invalid Date') {
      setDateErr(true);
      return;
    }

    // validate num tires
    if (!isNormalInteger(items[0].count)) {
      items[0].countErr = true;
      setItems([...items]);
      return;
    }
    if (!isValidFloat(items[0].price)) {
      items[0].priceErr = true;
      setItems([...items]);
      return;
    }

    let err = false;
    items.slice(1).forEach((item, idx) => {
      if (items[idx+1].count && !isNormalInteger(items[idx+1].count)) {
        items[idx+1].countErr = true;
        setItems([...items]);
        err = true;
      }
      if (items[idx+1].price && !isValidFloat(items[idx+1].price)) {
        items[idx+1].priceErr = true;
        setItems([...items]);
        err = true;
      }
    });
    if (err) return;

    // validate store
    setIsLoading(true);
    API.get("api", "stores/" + storeNum.padStart(4, '0')).then(async resp => {
      // store not in ddb
      if (!resp) { 
        setStoreNumErr(true);
        setIsLoading(false);
        return;
      }

      const itemsAPI = [];
      items.forEach(item => {
        if (item.count && item.price)
          itemsAPI.push({
            num_tire: item.count,
            unit_price: item.price,
            note: item.note
          });
      });

      // create invoice
      API.post("api", "create_invoice", {
        body: {
          items: itemsAPI,
          storeNum: storeNum.padStart(4, '0'),
          date: date.toISOString(),
          hauler: haulerData
        }
      }).then(() => props.history.push('/invoices'));
    });
  }

  return (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Create Invoice for Pickup
          </Typography>
          <Grid container spacing={3}>
            { props.cognito && props.cognito.role === 'admin' && 
              <React.Fragment>
              <Grid item xs={6} style={{fontSize: "1.15rem"}}>
                <FormControl 
                  required 
                  style={{minWidth: 140}} 
                  className={classes.formControl}
                  error={haulerErr}
                >
                  <InputLabel>Hauler</InputLabel>
                  <Select
                    value={hauler}
                    onChange={e => setHauler(e.target.value)}
                  >
                    {
                      haulers.map(hauler => (
                        <MenuItem key={hauler} value={hauler}>{hauler}</MenuItem>
                      ))
                    }
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={6}>
                <span/>
              </Grid>
              </React.Fragment>
            }
            <Grid item xs={4} sm={3}>
              <TextField
                required
                label="Store#"
                error={storeNumErr}
                value={storeNum}
                onChange={e => setStoreNum(e.target.value)} 
                helperText={storeNumErr ? "Invalid Store#" : null}
                inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
              />
            </Grid>
            <Grid item xs={6} sm={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  error={dateErr}
                  disableToolbar
                  variant="inline"
                  format="MM-dd-yyyy"
                  label="Date"
                  inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                  InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                  value={date}
                  onChange={e => setDate(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={6} sm={4}>
              <span/>
            </Grid>
            {items.map((item, idx) => (
              <React.Fragment key={idx}>
              <Grid item xs={8} sm={3}>
                <TextField 
                  required
                  error={items[idx].countErr}
                  onChange={e => {
                    items[idx].count = e.target.value;
                    setItems([...items]);
                  }}
                  value={item.count} 
                  id="expDate" 
                  label="Tire count" 
                  inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                  InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                  type="number"
                />
              </Grid>
              <Grid item xs={8} sm={3}>
                <TextField 
                  required
                  error={items[idx].priceErr}
                  onChange={e => {
                    items[idx].price = e.target.value;
                    setItems([...items]);
                  }}
                  value={item.price}
                  label="Unit price" 
                  inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                  InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                  helperText="USD"
                  type="number"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label="Note"
                  helperText="Additional information for item"
                  value={item.note}
                  onChange={e => {
                    items[idx].note = e.target.value;
                    setItems([...items]);
                  }}
                  fullWidth
                  inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                  InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                />
              </Grid>
              </React.Fragment>
            ))}
          </Grid>
        </React.Fragment>
        <Grid>
          <Grid item xs={12}>
            <Button
              style={{marginLeft: "auto", marginTop: "10px"}}
              variant="contained"
              color="secondary"
              size="small"
              onClick={() => {
                items.push({count: '', price: '', note: '', countErr: false, priceErr: false});
                setItems([...items]);
              }}
            >
              <AddIcon style={{marginLeft: "-5px", marginRight: "3px"}} /> Add Line Item
            </Button>
          </Grid>
        </Grid>
        <div style={{display: "flex", "width": "100%"}}>
          <span 
            style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
            onClick={() => {
              if (isLoading) return;
              props.history.goBack();
            }}
          >
            <BackIcon />
            <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
              Back
            </span>
          </span>
          {isLoading ? (
            <CircularProgress size={26} style={{marginLeft: "auto", marginTop: "20px"}} />
          ) : (
            <Button
              style={{marginLeft: "auto", marginTop: "10px"}}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={handleCreate}
            >
              Create
            </Button>
          )}
        </div>
      </Paper>
    </Grid>
  );
}
