import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  loader: {
    position: 'absolute', 
    zIndex: 120, 
    top: 0, 
    left: 240, 
    width: 'calc(100% - 240px)', 
    height: '100%', 
    display: 'flex', 
    justifyContent: 
    'center', 
    alignItems: 'center', 
    background: 'rgba(255,255,255,0.8)'
  },  
  loaderIsSmall: {
    position: 'absolute', 
    zIndex: 120, 
    top: 0, 
    left: 0, 
    width: 'calc(100% - 0px)', 
    height: '100%', 
    display: 'flex', 
    justifyContent: 
    'center', 
    alignItems: 'center', 
    background: 'rgba(255,255,255,0.8)'
  },
}));
