import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { API } from "aws-amplify";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import moment from 'moment';

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import { Typography } from "../../components/Wrappers/Wrappers";

export default function Reports(props) {
  function getMuiTheme() {
    return createTheme({
      overrides: {
        MuiTableRow: {
          hover: {
            '&$root': {
              '&:hover': {
                cursor: 'pointer', // make row's look clickable
                backgroundColor: 'rgba(74, 74, 74, 0.08)' // force bg color
              },
            }
          },
        },
        MuiIconButton: {
          root: {
            color: '#116466 !important', // primary color
          }
        },
        MUIDataTableHeadCell: { // force column width
          root: {
            '&:nth-child(1)': {
              width: 150
            },
            '&:nth-child(2)': {
              width: 150
            },
            '&:nth-child(3)': {
              width: 200
            },
            '&:nth-child(4)': {
              width: 150
            }
          }
        }
      }
    });
  }

  // state
  const [tickets, setTickets] = useState(null); // raw data
  const [ticketsTable, setTicketsTable] = useState([]); // data for mui table

  // data table settings
  const states = {
    Open: "secondary",
    Pending: "info",
    Closed: "primary"
  };

  const columns = [
    {
      label: "Date",
      name: "Date",
      options: {    
        filter: false,
        customBodyRender: date => (
          <React.Fragment>
            {moment(date).format('MM-DD-YYYY')}
          </React.Fragment>
        )
      },
    }, 
    {
      label: "Ticket ID",
      name: "Ticket ID",
    }, 
    {
      label: "Store#",
      name: "Store#",
    }, 
    {
      label: "Address",
      name: "Address",
    }, 
    {
      label: "Contact",
      name: "Contact",
    }, 
    {
      label: "Hauler",
      name: "Hauler",
    }, 
    {
      label: "Status",
      name: "Status",
      options: {    
        customBodyRender: status => (
          <Typography
            color={states[status]}
            weight="bold"
          >
            {status.toUpperCase()}
          </Typography>
        )
      },
    },
    {
      label: "Notes",
      name: "Notes",
    }, 
  ];

  const options = {
    filter: true,
    responsive: 'vertical',
    sortOrder: {
      name: 'Date',
      direction: 'desc'
    },
    rowsPerPage: ticketsTable.length,
    rowsPerPageOptions: [ticketsTable.length],
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (row,index) => {
      // get ticket_id from data (based on index)
      const ticket_id = tickets[index.dataIndex].ticket_id;
      props.history.push('/ticket?ticket_id=' + ticket_id);
    }
  };

  // init
  useEffect(() => {
    if (!props.cognito) return; // wait for cognito

    let params = {};
    if (props.cognito.role === 'store_manager')
      params = {queryStringParameters: {store_num: props.cognito.role_details}};
    else if (props.cognito.role === 'hauler')
      params = {queryStringParameters: {hauler: props.cognito.role_details}};
    
    // fetch tickets from ddb
    API.get('api', 'tickets', params).then(async resp => {
      // fetch stores from ddb
      const store_map = {};
      const stores = await API.get('api', 'stores');
      stores.forEach(store => {
        store_map[store.store_num] = store;
      });

      setTickets(resp);
      // build array for mui table
      const ticketsTable = resp.map(ticket => {
        return [
          ticket.date,
          ticket.legacy_id,
          ticket.store,
          <React.Fragment>
            <p>{store_map[ticket.store].address}</p>
            <p>{store_map[ticket.store].state}</p>
          </React.Fragment>,
          store_map[ticket.store].contact,
          ticket.hauler,
          ticket.status,
          ticket.notes.map((note, idx) => 
          <p key={idx}>{moment(Object.keys(note)[0].split('Z')[0]+'Z').format('MM-DD-YY h:mma') + ': ' + Object.values(note)[0]}</p>
          )
        ];
      });
      setTicketsTable(ticketsTable);
    });
  }, [props.cognito]);

  return (
    <React.Fragment>
      <PageTitle 
        title="Ticket Report"
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {tickets === null ? <Loader isSmall={props.isSmall}/> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Pickup Requests"
              data={ticketsTable}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
