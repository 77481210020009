import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import moment from "moment";

// libs
import { querystring } from "../../libs/querystring"

// components
import Loader from "../../components/Loader/Loader";

// styles
import useStyles from "./styles";

export default function Invoice(props) {
  const classes = useStyles();

  //state
  const [invoice, setInvoice] = useState(null);

  useEffect(() => {
    // get invoice id from url
    const invoice_id = querystring('invoice_id');
    // fetch invoice from ddb
    API.get('api', 'invoices/' + invoice_id).then(resp => {
      setInvoice(resp);
    }).catch(() => console.log('here')); // TODO: handle error
  }, []);

  return (
    invoice ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Pickup Invoice
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Store#
                </span> {invoice.store}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Date
                </span> {moment(invoice.date).format('MM-DD-YYYY h:mma')}
              </Grid>
              { invoice.items.map((item, idx) => 
                  <React.Fragment key={idx}>
                  <Grid item xs={4} style={{fontSize: "1.15rem"}}>
                    <span style={{fontWeight: "600", paddingRight: "3px"}}>
                      Tire count
                    </span> {item.num_tire}
                  </Grid>
                  <Grid item xs={5} style={{fontSize: "1.15rem"}}>
                    <span style={{fontWeight: "600", paddingRight: "3px"}}>
                      Unit Price
                    </span> {'$' + parseFloat(item.unit_price).toFixed(2)}
                  </Grid>
                  <Grid item xs={3} style={{fontSize: "1.15rem"}}>
                    <span style={{fontWeight: "600", paddingRight: "3px"}}>
                      Note
                    </span> {item.note}
                  </Grid>
                  </React.Fragment>
                )
              }
            </Grid>
          </React.Fragment>
          <div style={{display: "flex", "width": "100%"}}>
            <span 
              style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
              onClick={() => props.history.goBack()} 
            >
              <BackIcon />
              <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
                Back
              </span>
            </span>
            <Button
              style={{marginLeft: "auto", marginTop: "10px", "backgroundColor": "#F44336"}}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={() => {
                API.del('api', 'invoices_delete/' + invoice.invoice_id).then(resp => {
                  props.history.push("/invoices");
                  // TODO: prevent dupes
                })
              }}
            >
              Delete
            </Button>
          </div>
        </Paper>
      </Grid>
    ) : ( <Loader isSmall={props.isSmall}/> )
  );
}
