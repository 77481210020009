/****************************************************/
// Customers.js
//
// A table of all customers in the database.
// Rows:
// Name|City|Emails|After Hours|Rates|Last Pickup|
/****************************************************/
import React, { useState, useEffect } from "react";
import { Grid, Button } from "@material-ui/core";
import { Add as AddIcon, Delete } from "@material-ui/icons";
import MUIDataTable from "mui-datatables";
import { API } from "aws-amplify";
import { createTheme, MuiThemeProvider } from "@material-ui/core/styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

import useStyles from "./styles";

function convertDateTime(dt) {
	return new Date(dt).toLocaleDateString({
		year: "numeric",
		month: "long",
		day: "numeric",
	});
}
export default function Customers(props) {
	const classes = useStyles();
	function getMuiTheme() {
		return createTheme({
			overrides: {
				MuiTableRow: {
					hover: {
						"&$root": {
							"&:hover": {
								cursor: "pointer", // make row's look clickable
								backgroundColor: "rgba(74, 74, 74, 0.08)", // force bg color
							},
						},
					},
				},
				MuiTableCell: {
					root: {
						padding: "15px 20px 15px 30px",
					},
				},
				MuiIconButton: {
					root: {
						color: "#116466 !important", // primary color
					},
				},
				MUIDataTableHeadCell: {
					// force column width
					root: {
						"&:nth-child(1)": {
							width: 350,
						},
						"&:nth-child(2)": {
							width: 175,
						},
						"&:nth-child(3)": {
							width: 250,
						},
						"&:nth-child(4)": {
							width: 100,
						},
						"&:nth-child(5)": {
							width: 100,
						},
						"&:nth-child(6)": {
							width: 150,
						},
					},
				},
			},
		});
	}

	// state
	const [customers, setCustomers] = useState([]); // raw data
	const [customersTable, setCustomersTable] = useState([]); // data for mui table

	const columns = [
		{
			label: "Name",
			name: "Name",
			options: {
				filter: false,
			},
		},
		{
			label: "City",
			name: "City",
		},
		{
			label: "Emails",
			name: "Emails",
			options: {
				filter: false,
			},
		},
		{
			label: "After Hours",
			name: "After Hours",
		},
		{
			label: "Rates",
			name: "Rates",
			options: {
				filter: false,
			},
		},
		{
			label: "Last Pickup",
			name: "Last Pickup",
			options: {
				filter: false,
			},
		},
		{
			label: "",
			name: "",
			options: {
				customBodyRenderLite: (dataIndex, _) => {
					return (
						<Button 
							style={{ maxWidth: "20px"}}
							onClick={(e) => {
								e.stopPropagation(); // stop the onRowClick event
								const id = customers[dataIndex].customer_id;
								console.log(`deleting customer ${id}`);
                API.del('api', 'customers_delete/' + id).then( _ => {
									setCustomers(customers.filter((_, index) => index !== dataIndex));
									setCustomersTable(customersTable.filter((_, index) => index !== dataIndex));
                  props.history.push("/customers");
									// remove from table
                })
							}} 
							variant="outlined" 
							startIcon={<Delete />} 
						/>
					)
				}
			}
		},
	];

	const options = {
		filter: true,
		responsive: "vertical",
		rowsPerPage: 10,
		rowsPerPageOptions: [10, 25, 50],
		filterType: "checkbox",
		selectableRows: "none",
		onRowClick: (_, index) => {
			// get ticket_id from data (based on index)
			const customer_id = customers[index.dataIndex].customer_id;
			props.history.push("/customer?customer_id=" + customer_id);
		},
	};

	// init
	useEffect(() => {
		if (!props.cognito) return; // wait for cognito

		let params = {};
		if (props.cognito.role === "hauler")
			params = {
				queryStringParameters: { hauler: props.cognito.role_details },
			};

		// fetch stores from ddb
		API.get("api", "customers", params).then((resp) => {
			setCustomers(resp);

			// build array for mui table
			const customersTable = resp.map((customer) => {
				let rates = Object.entries(customer.rates).map(([key, value]) => {
					const formatted = new Intl.NumberFormat("en-US", {
						style: "currency",
						currency: "USD",
					}).format(value);

					return (
						<li style={{ listStyleType: "none" }} key={key}>
							{key + ": " + formatted}
						</li>
					);
				});

				let emails = customer.emails.map((item) => {
					return <li style={{ listStyleType: "none" }}>{item}</li>;
				});

				return [
					customer.name,
					customer.city,
					emails,
					customer.after_hours ? `\u2705` : `\u274C`,
					rates,
					customer.last_pickup ? convertDateTime(customer.last_pickup) : "",
				];
			});
			setCustomersTable(customersTable);
		});
	}, [props.cognito]);

	const buttons = [];
	if (props.cognito && props.cognito.role === "admin") {
		buttons.push({
			text: "Add Customer",
			icon: <AddIcon className={classes.icon} />,
			func: () => props.history.push("/create_customer"),
		});
	}
	return (
		<React.Fragment>
			<PageTitle title="Customers" buttons={buttons.length ? buttons : null} />
			<Grid container spacing={4}>
				<Grid item xs={12}>
					{!customersTable.length ? <Loader isSmall={props.isSmall} /> : null}
					<MuiThemeProvider theme={getMuiTheme()}>
						<MUIDataTable
							title="Customers"
							data={customersTable}
							columns={columns}
							options={options}
						/>
					</MuiThemeProvider>
				</Grid>
			</Grid>
		</React.Fragment>
	);
}
