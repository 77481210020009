import React from "react";
import { Button } from "@material-ui/core";

// styles
import useStyles from "./styles";

// components
import { Typography } from "../Wrappers/Wrappers";

export default function PageTitle(props) {
  var classes = useStyles();

  return (
    <div className={classes.pageTitleContainer}>
      <Typography className={classes.typo} variant="h1" size="sm">
        {props.title}
      </Typography>
      {Array.isArray(props.buttons) && props.buttons.length === 2 && (
        <Button
          style={{marginLeft: 'auto', marginRight: '20px'}}
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
          onClick={props.buttons[1].func}
        >
          {props.buttons[1].icon}
          {props.buttons[1].text}
        </Button>
      )}
      {Array.isArray(props.buttons) && props.buttons.length > 0 && (
        <Button
          classes={{ root: classes.button }}
          variant="contained"
          size="large"
          color="secondary"
          onClick={props.buttons[0].func}
        >
          {props.buttons[0].icon}
          {props.buttons[0].text}
        </Button>
      )}
    </div>
  );
}
