import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  TextField,
  Fade
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import { Auth } from "aws-amplify"; 

// styles
import useStyles from "./styles";

// logo
import logo from "../../assets/logo.svg";

function Forgot(props) {
  var classes = useStyles();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState("");
  var [request, setRequested] = useState(false);
  var [email, setEmail] = useState("");
  var [code, setCode] = useState("");
  var [passwordValue, setPasswordValue] = useState("");

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={logo} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>FacilityChex</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          {!request ? (
            <React.Fragment>
              <form>
              <TextField
                id="email"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={email}
                onChange={e => setEmail(e.target.value)}
                margin="normal"
                placeholder="Email"
                type="email"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={async () => {
                      setIsLoading(true);

                      await Auth.forgotPassword(email);

                      setIsLoading(false);
                      setRequested(true);
                    }}
                    disabled={email.length === 0}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                    type='submit'
                  >
                    Request Password Change
                  </Button>
                )}
              </div>
              </form>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Fade in={error ? true : false}>
                <Typography color="secondary" className={classes.errorMessage}>
                  {error}
                </Typography>
              </Fade>
              <form>
              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={code}
                onChange={e => setCode(e.target.value)}
                margin="normal"
                placeholder="Verification Code"
                fullWidth
              />
              <TextField
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordValue}
                onChange={e => setPasswordValue(e.target.value)}
                margin="normal"
                placeholder="New Password"
                type="password"
                fullWidth
              />
              <div className={classes.creatingButtonContainer}>
                {isLoading ? (
                  <CircularProgress size={26} />
                ) : (
                  <Button
                    onClick={async () => {
                      setIsLoading(true);

                      try {
                        await Auth.forgotPasswordSubmit(email, code, passwordValue);

                        await Auth.signIn(email, passwordValue);

                        props.userHasAuthenticated(true);
                      } catch (e) {
                        setError(e.message);
                        setIsLoading(false);
                      }
                    }}
                    disabled={passwordValue.length === 0 || code.length === 0}
                    size="large"
                    variant="contained"
                    color="primary"
                    fullWidth
                    className={classes.createAccountButton}
                    type='submit'
                  >
                    Submit Password Change
                  </Button>
                )}
              </div>
              </form>
            </React.Fragment>
          )}
        </div>
        <Typography color="primary" className={classes.copyright}>
          © 2020 FacilityChex. All rights reserved.
        </Typography>
      </div>
    </Grid>
  );
}

export default withRouter(Forgot);
