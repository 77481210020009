import React, { useState } from "react";
import { Grid, Paper, Typography, Button, CircularProgress,  FormControl,
  InputLabel,
  MenuItem, Select,
  FormControlLabel, Checkbox, TextField } from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import { API } from "aws-amplify"; 
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { Page, Text, View, Document, StyleSheet, pdf } from '@react-pdf/renderer';
import JSZip from "jszip";
import moment from "moment";

// styles
import useStyles from "./styles";

// TODO: put in DDB
const haulers = {
  "Ben Tire": {
    name: "Ben Tire",
    street: "3401 Tremley Point Rd Unit #6",
    city: "Linden, NJ 07036",
    phone: "908-862-3170",
    email: "bentirerecycling@gmail.com"
  },
  "Black to Green": {
    name: "Black to Green",
    street: "139 N Four Bridges Rd",
    city: "Long Valley, NJ 07853",
    phone: "",
    email: ""
  },
  "Emanuel": {
    name: "Emanuel Tire of Pennsylvania, Inc.",
    street: "1304 Conshohocken Rd",
    city: "Conshohocken, PA 19428",
    phone: "610-277-4520",
    email: ""
  },
  "Best Haulers": {
    name: "Best Haulers, Inc.",
    street: "15 Garfield St Suite B",
    city: "Auburn, NY 13021",
    phone: "315-252-8000",
    email: "Besthaulersinc@gmail.com"
  },
  "Liberty": {
    name: "Liberty",
    street: "",
    city: "",
    phone: "",
    email: ""
  },
  "Liberty Lakin": {
    name: "Liberty Lakin",
    street: "",
    city: "",
    phone: "",
    email: ""
  },
  "tyrex": {
    name: "tyrex",
    street: "",
    city: "",
    phone: "",
    email: ""
  }
};

// PDF styles
const BORDER_COLOR = '#bfbfbf'
const BORDER_STYLE = 'solid'
const COL1_WIDTH = 45
const COLN_WIDTH = (100 - COL1_WIDTH) / 3
const styles = StyleSheet.create({
  body: {
    padding: 40,
    fontSize: 13
  },
  table: { 
    display: "table", 
    width: "auto", 
  }, 
  tableRow: { 
    margin: "auto", 
    flexDirection: "row" 
  }, 
  tableCol1Header: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
  },     
  tableColHeader: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
  }, 
  tableColHeaderEnd: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderBottomColor: '#000',
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderRightWidth: 0,
  }, 
  
  tableCol1: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  },   
  tableCol1Total: { 
    width: COL1_WIDTH + '%', 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 0, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableCol: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0 
  }, 
  tableColEnd: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 1, 
    borderLeftWidth: 0, 
    borderTopWidth: 0,
    borderRightWidth: 0 
  }, 
  tableColTotal: { 
    width: COLN_WIDTH + "%", 
    borderStyle: BORDER_STYLE, 
    borderColor: BORDER_COLOR,
    borderWidth: 0, 
  }, 
  
  tableCellHeader: {
    margin: 5, 
    fontSize: 13
  },  
  tableCell: { 
    margin: 5, 
    fontSize: 10 
  },
  tableCellTotal: { 
    margin: 5, 
    fontSize: 13
  },
  billTo: {
    fontSize: 13
  },
  store: {
    fontSize: 13,
    margin: 6,
    marginLeft: 10
  }
});

function genSpreadsheet(invoices, exportAs) {
  let total = 0;
  // add bill_to and totals
  const invoice_rows = [];
  invoices.forEach(invoice => {
    invoice.items.forEach(item => {
      let invoice_row = {
        "hauler": exportAs ? 'Best Haulers' : invoice.hauler,
        "bill_to": invoice.hauler === 'Best Haulers' || exportAs ? "Mavis" : "Best Haulers, Inc.",
        'store': invoice.store, 
        "invoice_id": invoice.invoice_id, 
        "date": moment(invoice.date).format('MM-DD-YYYY'), 
        'num_tire': item.num_tire, 
        "unit_price": parseFloat(item.unit_price).toFixed(2), 
        "total": parseFloat(parseFloat(item.num_tire) * parseFloat(item.unit_price)).toFixed(2),
        "note": item.note
      };
      total += parseFloat(invoice_row.total);
      invoice_row.total = '$' + invoice_row.total;
      invoice_rows.push(invoice_row);
    });
  });
  invoice_rows.push({total: '$' + parseFloat(total).toFixed(2)});

  // custom header
  invoice_rows.unshift({
    "hauler": "VendorName",
    "bill_to": "BillToName",
    'store': "StoreNumber", 
    "invoice_id": "InvoiceNumber", 
    "date": "InvoiceDate", 
    'num_tire': "Quantity", 
    "unit_price": "UnitPrice", 
    "total": "Invoicetotal",
    "note": ""
  });
  
  const ws = XLSX.utils.json_to_sheet(invoice_rows, {
    header: ["hauler", "bill_to", 'store', "invoice_id", "date", 'num_tire', "unit_price", "total", "note"], 
    skipHeader: true
  });
  const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
  const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
  const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const blob = new Blob([excelBuffer], {type: fileType});
  return blob;
}

function genPDFLine(item, idx) {
  return (
    <View key={idx} style={styles.tableRow}> 
      <View style={styles.tableCol1}> 
        <Text style={styles.tableCell}>Scrap tires {item.note && "(" + item.note + ")"}</Text> 
      </View> 
      <View style={styles.tableCol}> 
        <Text style={styles.tableCell}>{item.num_tire}</Text> 
      </View> 
      <View style={styles.tableCol}>
        <Text style={styles.tableCell}>${parseFloat(item.unit_price).toFixed(2)}</Text> 
      </View>
      <View style={styles.tableColEnd}> 
        <Text style={styles.tableCell}>{parseFloat(parseFloat(item.num_tire) * parseFloat(item.unit_price)).toFixed(2)}</Text> 
      </View> 
    </View> 
  );
}

async function genPDF(invoices, exportAs) {
  const blobs = Promise.all(invoices.map(async invoice => {
    let total = 0;
    invoice.items.forEach(item => {
      total += parseFloat(parseFloat(item.num_tire) * parseFloat(item.unit_price));
    });
    const reactPDF = (
      <Document>
        <Page style={styles.body}>
          
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <Text>{haulers[invoice.hauler].name}</Text>
            </View>
            
            <View style={{flex: 1}}>
              <Text style={{textAlign: 'right'}}>INVOICE</Text>
            </View>
          </View>
          
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <Text>{haulers[invoice.hauler].street ? haulers[invoice.hauler].street : ""}</Text>
            </View>
            <View style={{flex: 1}}>
              <Text style={{textAlign: 'right', fontSize: 11}}>{invoice.invoice_id}</Text>
            </View>
          </View>
          
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <Text>{haulers[invoice.hauler].city ? haulers[invoice.hauler].city : ""}</Text>
            </View>
            <View style={{flex: 1}}>
              <Text style={{textAlign: 'right'}}>DATE</Text>
            </View>
          </View>
          
          <View style={{flexDirection: 'row'}}>
            <View style={{flex: 1}}>
              <Text>{haulers[invoice.hauler].phone ? haulers[invoice.hauler].phone : ""}</Text>
            </View>
            <View style={{flex: 1}}>
              <Text style={{textAlign: 'right', fontSize: 11}}>{moment(invoice.date).format('MM-DD-YYYY')}</Text>
            </View>
          </View>
          
          <View style={{flexDirection: 'row', paddingBottom: 30}}>
            <View style={{flex: 1}}>
              <Text>{haulers[invoice.hauler].email ? haulers[invoice.hauler].email : ""}</Text>
            </View>
          </View>
          
          <Text style={styles.billTo}>Bill To</Text>
          <Text style={styles.store}>{invoice.hauler === 'Best Haulers' || exportAs ? "Mavis #" + invoice.store : "Best Haulers, Inc."}</Text>
          <View style={styles.table}> 
            
            <View style={styles.tableRow}> 
              <View style={styles.tableCol1Header}> 
                <Text style={styles.tableCellHeader}>Description</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                <Text style={styles.tableCellHeader}>Qty</Text> 
              </View> 
              <View style={styles.tableColHeader}> 
                <Text style={styles.tableCellHeader}>Unit Price</Text> 
              </View> 
              <View style={styles.tableColHeaderEnd}> 
                <Text style={styles.tableCellHeader}>Amount</Text> 
              </View> 
            </View>
            
            {invoice.items.map((item, idx) => genPDFLine(item, idx))}

            <View style={styles.tableRow}> 
              <View style={styles.tableCol1Total}> 
                <Text style={styles.tableCell}></Text> 
              </View> 
              <View style={styles.tableColTotal}> 
                <Text style={styles.tableCell}></Text> 
              </View> 
              <View style={styles.tableColTotal}>
                <Text style={styles.tableCellTotal}>Total</Text> 
              </View>
              <View style={styles.tableColTotal}> 
                <Text style={styles.tableCellTotal}>${total.toFixed(2)}</Text> 
              </View> 
            </View> 

          </View>
        </Page>
      </Document>
    );

    const blob = await pdf(reactPDF).toBlob();
    return {
      data: blob,
      id: invoice.invoice_id
    };    
  }));

  return blobs;
}

export default function Summary(props) {
  const classes = useStyles();

  //state
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(new Date(moment().startOf('day')));
  const [startDateErr, setStartDateErr] = useState(false);
  const [endDate, setEndDate] = useState(new Date(moment().endOf('day')));
  const [endDateErr, setEndDateErr] = useState(false);
  const [period, setPeriod] = useState('');
  const [hauler, setHauler] = useState('');
  const [haulerErr, setHaulerErr] = useState(false);
  const [exportAs, setExportAs] = useState(false);
  const [exportingAs, setExportingAs] = useState(false);
  const [newUnitPrices, setNewUnitPrices] = useState(null);
  const [invoices, setInvoices] = useState(null);

  function generate() {
    // reset form errors
    setStartDateErr(false);
    setEndDateErr(false);
    setHaulerErr(false);

    // check dates
    if (!startDate) {
      setStartDateErr(true);
      return;
    }
    if (!endDate) {
      setEndDateErr(true);
      return;
    }
    if (endDate < startDate) {
      setStartDateErr(true);
      setEndDateErr(true);
      return;
    }

    let haulerData;
    if (props.cognito.role === 'admin') {
      if (!hauler.length) {
        setHaulerErr(true);
        return;
      }
      if (!Array.isArray(hauler))
        haulerData = [hauler];
      else
        haulerData = hauler;
    } else {
      haulerData = [props.cognito.role_details];
    }

    let params = {queryStringParameters: {
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      hauler: haulerData.join()
    }};

    // fetch invoices from ddb and generate invoices
    setIsLoading(true);
    API.get('api', 'invoices', params).then(async resp => {
      if (exportAs) {
        const unit_prices = {};
        resp.forEach(invoice => {
          if (!unit_prices.hasOwnProperty(invoice.hauler))
            unit_prices[invoice.hauler] = {'Scrap tires': ''}
          invoice.items.forEach(item => {
            if (item.note) unit_prices[invoice.hauler][item.note] = '';
          });
        });
        setInvoices(resp);
        setExportingAs(true);
        setNewUnitPrices(unit_prices);
        setIsLoading(false);
        return;
      }

      // speadsheet
      const spreadsheet = genSpreadsheet([...resp], exportAs);
      
      // pdfs
      const pdfs = await genPDF(resp, exportAs);

      // build zip
      const zip = new JSZip();
      zip.file("invoices.xlsx", spreadsheet);
      const invoicesZip = zip.folder("invoices");
      pdfs.forEach(pdf => invoicesZip.file(pdf.id + ".pdf", pdf.data));
      zip.generateAsync({type:"blob"}).then(function(content) {
        FileSaver.saveAs(content, "invoices.zip");
        setIsLoading(false);
      });
    });
  }

  async function finishGenerate() {
    setIsLoading(true);
    
    invoices.forEach(invoice => {
      invoice.items.forEach(item => {
        item.unit_price = newUnitPrices[invoice.hauler][item.note ? item.note : 'Scrap tires'];
      });
      invoice.hauler = 'Best Haulers';
    });

    // speadsheet
    const spreadsheet = genSpreadsheet([...invoices], exportAs);
    
    // pdfs
    const pdfs = await genPDF(invoices, exportAs);

    // build zip
    const zip = new JSZip();
    zip.file("invoices.xlsx", spreadsheet);
    const invoicesZip = zip.folder("invoices");
    pdfs.forEach(pdf => invoicesZip.file(pdf.id + ".pdf", pdf.data));
    zip.generateAsync({type:"blob"}).then(function(content) {
      FileSaver.saveAs(content, "invoices.zip");
      setIsLoading(false);
      setExportingAs(false);
    });
  }

  return (
    <Grid container className={classes.container}>
      { !exportingAs ?
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Generate Invoice Summary
            </Typography>
            <Grid container spacing={3} style={{paddingBottom: "10px"}}>
              { props.cognito && props.cognito.role === 'admin' &&
                <React.Fragment>
                <Grid item xs={7}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={exportAs}
                        onChange={() => {
                          setExportAs(!exportAs);
                          if (!exportAs) setHauler([]);
                          else setHauler('');
                        }}
                        color="secondary"
                      />
                    }
                    label="Export as Best Haulers?"
                    style={{marginTop: "9px"}}
                  />
                </Grid>
                <Grid item xs={5}>
                  <span/>
                </Grid>
                </React.Fragment>
              }
              { props.cognito && props.cognito.role === 'admin' && 
                <Grid item xs={4} style={{fontSize: "1.15rem"}}>
                  <FormControl 
                    required 
                    style={{minWidth: 140}} 
                    className={classes.formControl}
                    error={haulerErr}
                  >
                    <InputLabel>{exportAs ? "Hauler(s)" : "Hauler" }</InputLabel>
                    <Select
                      value={hauler}
                      onChange={e => setHauler(e.target.value)}
                      multiple={exportAs}
                    >
                      {
                        Object.keys(haulers).map(hauler => (
                          <MenuItem key={hauler} value={hauler}>{hauler}</MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Grid>
              }
              <Grid item xs={10} style={{fontSize: "1.15rem"}}>
                <FormControl style={{minWidth: 120}} className={classes.formControl}>
                  <InputLabel>Period</InputLabel>
                  <Select
                    value={period}
                    onChange={e => {
                      setPeriod(e.target.value);
                      if (e.target.value === 'week') {
                        setStartDate(new Date(moment().subtract(1, 'weeks').startOf('week').subtract(1, 'days')));
                        setEndDate(new Date(moment().subtract(1, 'weeks').endOf('week').subtract(1, 'days')));
                      } else if (e.target.value === 'month') {
                        setStartDate(new Date(moment().subtract(1, 'months').startOf('month')));
                        setEndDate(new Date(moment().subtract(1, 'months').endOf('month')));
                      } else {
                        setStartDate(new Date(moment().startOf('year')));
                        setEndDate(new Date(moment().endOf('day')));
                      }
                    }}
                  >
                    <MenuItem value={'week'}>Last Week</MenuItem>
                    <MenuItem value={'month'}>Last Month</MenuItem>
                    <MenuItem value={'ytd'}>YTD</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={6} sm={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={startDateErr}
                    disableToolbar
                    variant="inline"
                    format="MM-dd-yyyy"
                    label="Start Date"
                    inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                    InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                    value={startDate}
                    onChange={e => setStartDate(new Date(moment(e).startOf('day')))}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={6} sm={5}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    error={endDateErr}
                    disableToolbar
                    variant="inline"
                    format="MM-dd-yyyy"
                    label="End Date"
                    inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                    InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                    value={endDate}
                    onChange={e => setEndDate(new Date(moment(e).endOf('day')))}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    autoOk={true}
                  />
                </MuiPickersUtilsProvider>
              </Grid>
            </Grid>
          </React.Fragment>
          <div style={{display: "flex", "width": "100%"}}>
            <span 
              style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
              onClick={() => {
                if (isLoading) return;
                props.history.goBack();
              }}
            >
              <BackIcon />
              <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
                Back
              </span>
            </span>
            {isLoading ? (
              <CircularProgress size={26} style={{marginLeft: "auto", marginTop: "20px"}} />
            ) : (
              <Button
                style={{marginLeft: "auto", marginTop: "10px"}}
                variant="contained"
                color="secondary"
                size="large"
                className={classes.backButton}
                disabled={!props.cognito}
                onClick={generate}
              >
                Generate
              </Button>
            )}
          </div>
        </Paper>
        :
        <Paper classes={{ root: classes.paperRoot }}>
        <Typography variant="h3" gutterBottom>
          Generate Invoice Summary
        </Typography>
        {
          newUnitPrices && Object.keys(newUnitPrices).map(hauler => 
            <React.Fragment>
            <Typography key={hauler} style={{fontSize: "1.2rem", marginTop: "12px"}}>
              {hauler}
            </Typography>
            {
              Object.keys(newUnitPrices[hauler]).map(item => (
              <Grid key={item} item xs={9}>
                <TextField 
                  onChange={e => {
                    newUnitPrices[hauler][item] = e.target.value;
                    setNewUnitPrices(Object.assign({}, newUnitPrices));
                  }}
                  value={newUnitPrices[hauler][item]}
                  label={item !== 'Scrap tires' ? 'Scrap tires - ' + item : item}
                  inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                  InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                  type="number"
                  helperText="New unit price"
                  style={{marginBottom: "8px"}}
                />
              </Grid>
              ))
            }
            </React.Fragment>
            )
        }
        <div style={{display: "flex", "width": "100%"}}>
          <span 
            style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
            onClick={() => {
              if (isLoading) return;
              setExportingAs(false);
            }}
          >
            <BackIcon />
            <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
              Back
            </span>
          </span>
          {isLoading ? (
            <CircularProgress size={26} style={{marginLeft: "auto", marginTop: "20px"}} />
          ) : (
            <Button
              style={{marginLeft: "auto", marginTop: "10px"}}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              disabled={!props.cognito}
              onClick={finishGenerate}
            >
              Generate
            </Button>
          )}
        </div>
      </Paper>
      }
    </Grid>
  );
}
