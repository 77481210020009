import React, { useEffect } from "react";
import { getCustomerErrors } from "../../libs/Errors";
import Customer from "./Customer";
import { API } from "aws-amplify";
import currency from "currency.js";

export default function Update(props) {
  //init
  useEffect(() => {}, []);

  const handleCreate = (newCustomer) => {
    let errorString = getCustomerErrors(newCustomer);

    if (errorString) {
      alert(errorString);
      return;
    }

    API.post("api", "create_customer/", {
      body: newCustomer,
    }).then(() => props.history.push("/customers"));
  };

  return <Customer mode="create" onSubmit={handleCreate} {...props} />;
}
