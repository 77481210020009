import React, { useState, useEffect } from "react";
import { Paper, Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { API } from "aws-amplify";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import Chart from "react-apexcharts";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";

// styles
import useStyles from "./styles";

export default function Reports(props) {
  const classes = useStyles();

  function getMuiTheme() {
    return createTheme({
      overrides: {
        MuiTableRow: {
          hover: {
            '&$root': {
              '&:hover': {
                backgroundColor: 'rgba(74, 74, 74, 0.08)' // force bg color
              },
            }
          },
        },
        MuiIconButton: {
          root: {
            color: '#116466 !important', // primary color
          }
        },
        
      }
    });
  }

  // state
  const [tickets, setTickets] = useState(null); // raw data
  const [ticketsTable, setTicketsTable] = useState([]); // data for mui table
  const [series, setSeries] = useState(null); // chart data

  const columns = [
    {
      label: "Hauler",
      name: "Hauler",
    }, 
    {
      label: "Num. Tickets",
      name: "Num. Tickets",
      options: {    
        filter: false,
      },
    }, 
  ];

  const table_options = {
    filter: false,
    download: false,
    print: false,
    viewColumns: false,
    search: false,
    responsive: 'vertical',
    sortOrder: {
      name: 'Num. Tickets',
      direction: 'asc'
    },
    customFooter: () => <React.Fragment></React.Fragment>,
    filterType: "checkbox",
    selectableRows: "none",
    customSort: (data, colIndex, order) => {
      return data.sort((a, b) => {
      a = a.data[colIndex] || '';
      b = b.data[colIndex] || '';
      if (order === 'asc')
        return a.toString().localeCompare(b, undefined, { numeric: true });
      else
        return b.toString().localeCompare(a, undefined, { numeric: true });
      });
    }
  };

  const chart_options = {
    chart: {
      type: "area",
      stacked: false,
      zoom: {
        type: "x",
        enabled: true,
        autoScaleYaxis: true,
      },
      toolbar: {
        autoSelected: "zoom",
      },
    },
    dataLabels: {
      enabled: false,
    },
    title: {
      text: "Historical Open Tickets",
      align: "left",
      style: {
        fontSize: "21px",
        fontWeight: "400",
      },
    },
    yaxis: {
      title: {
        text: "Num. Tickets",
        style: {
          fontSize: "14px",
          fontWeight: "400",
        },
      },
    },
    xaxis: {
      type: "datetime",
      labels: {
        datetimeFormatter: {
          year: "yyyy",
          month: "MMM 'yy",
          day: "MMM dd",
          hour: "HH:mm",
        },
      },
    },
    stroke: {
      width: 2.5,
    },
    tooltip: {
      shared: true,
      y: {
        formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
          if (!w.config.series[seriesIndex].data.length) return null;
          const desc = w.config.series[seriesIndex].data[dataPointIndex].description;
          return (
            value +
            " open (" +
            desc.pending +
            " pending, " +
            desc.urgent +
            " urgent)"
          );
        },
      },
      x: {
        format: "MMM dd",
      },
    },
  };

  // init
  useEffect(() => {
    // fetch tickets from ddb
    API.get('api', 'tickets').then(resp => {
      setTickets(resp);
      const haulers = {}
      resp.filter(ticket => ticket.status !== 'Closed')
      .forEach(ticket => {
        if (!haulers.hasOwnProperty(ticket.hauler)) {
          haulers[ticket.hauler] = {open: 0, urgent: 0, pending: 0};
        }
        haulers[ticket.hauler].open += 1;
        if (ticket.urgency === 'Urgent') haulers[ticket.hauler].urgent += 1;
        if (ticket.status === 'Pending') haulers[ticket.hauler].pending += 1;
      });
      // build array for mui table
      const ticketsTable = Object.keys(haulers).map(hauler => {
        return [
          hauler,
          haulers[hauler].open + ' open (' + haulers[hauler].pending + ' pending, ' + haulers[hauler].urgent + ' urgent)',
        ];
      });
      setTicketsTable(ticketsTable);
    });

    // fetch reports from ddb
    API.get('api', 'reports').then(resp => {
      // get fixed list of hauler names
      const hauler_names = Object.keys(resp[0].open_tickets);
      const series = hauler_names.map(hauler => {
        return {
          name: hauler,
          data: []
        }
      });
      // sort reports by date (asc)
      resp.sort((a, b) => (a.date > b.date) ? 1 : -1);
      resp.forEach(report => {
        const date = new Date(report.date.slice(0, 10));
        const time = date.getTime();
        hauler_names.forEach((hauler, idx) =>
          series[idx].data.push({
            x: time, 
            y: report.open_tickets[hauler].open,
            description: {pending: report.open_tickets[hauler].pending, urgent: report.open_tickets[hauler].urgent}
          })
        );
      });
      setSeries(series);
    });
  }, []);

  return (
    <React.Fragment>
      <PageTitle 
        title="Reports" 
      />
      <Grid style={{justifyContent: 'center'}} container spacing={4}>
        <Grid item xs={4}>
          {tickets === null ? <Loader isSmall={props.isSmall}/> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Current Open Tickets"
              data={ticketsTable}
              columns={columns}
              options={table_options}
            />
          </MuiThemeProvider>
        </Grid>
        <Grid item xs={8}>
          {series === null ? <Loader isSmall={props.isSmall}/> : null}
          <Paper classes={{ root: classes.paperRoot }}>
            <div style={{width: '100%'}}>
            <Chart
              options={chart_options}
              series={series !== null ? series : []}
              width="100%"
            />
            </div>
          </Paper>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
