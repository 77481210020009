import React, { useState, useEffect } from "react";
import Customer from "./Customer";
import { API } from "aws-amplify";

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring";

export default function Update(props) {
  //state
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //init
  useEffect(() => {
    setIsLoading(true);
    const customer_id = querystring("customer_id");

    API.get("api", "customers/" + customer_id)
      .then((resp) => {
        setIsLoading(false);
        setCustomer(resp);
      })
      .catch((error) => console.log("errored:", error)); // TODO: handle error
  }, []);

  function handleGoToUpdate(customer) {
    // Go to update page
    props.history.push("/update_customer?customer_id=" + customer.customer_id);
  }

  return customer && !isLoading && Object.keys(customer).length > 0 ? (
    <Customer
      mode="view"
      customerData={customer}
      onSubmit={handleGoToUpdate}
      {...props}
    />
  ) : (
    <Loader isSmall={props.isSmall} />
  );
}
