import React from "react";
import { CircularProgress } from "@material-ui/core";

// styles
import useStyles from "./styles";

export default function Loader(props) {
  var classes = useStyles();
  
  return (
    <div className={props.isSmall ? classes.loaderIsSmall : classes.loader}>
      <CircularProgress size={36} />
    </div>
  );
}
