import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Button, CircularProgress, FormControlLabel,
  Checkbox } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import { API } from "aws-amplify"; 
import moment from 'moment';

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring"

// styles
import useStyles from "./styles";

export default function Update(props) {
  const classes = useStyles();

  //state
  const [ticket, setTicket] = useState(null);
  const [numTireIn, setNumTireIn] = useState('');
  const [numTireOut, setNumTireOut] = useState('');
  const [tireInErr, setTireInErr] = useState(false);
  const [tireOutErr, setTireOutErr] = useState(false);
  const [status, setStatus] = useState('Open');
  const [statusErr, setStatusErr] = useState(false);
  const [note, setNote] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [updateErr, setUpdateErr] = useState('');
  const [ETA, setETA] = useState(null);
  const [ETAErr, setETAErr] = useState(false);
  const [storeNum, setStoreNum] = useState("");
  const [storeNumErr, setStoreNumErr] = useState(false);
  const [isUrgent, setIsUrgent] = useState(false);

  useEffect(() => {
    // get ticket id from url
    const ticket_id = querystring('ticket_id');
    // fetch ticket from ddb
    API.get('api', 'tickets/' + ticket_id).then(resp => {
      setTicket(resp);
      setNumTireIn(resp.num_tire_in);
      setNumTireOut(resp.num_tire_out);
      setStatus(resp.status);
      setETA(resp.eta ? new Date(resp.eta) : null);
      setStoreNum(resp.store);
      setIsUrgent(resp.urgency === 'Urgent' ? true : false);
    }).catch(() => console.log('here')); // TODO: handle error
  }, []);

  function isNormalInteger(str) {
    var n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  }

  async function handleUpdate() {
    // reset form errors
    setStatusErr(false);
    setStoreNumErr(false);
    setTireInErr(false);
    setTireOutErr(false);
    setETAErr(false);
    setUpdateErr('');

    // initial data for update call
    const data = {
      date: new Date(Date.now()).toISOString(),
      role: props.cognito.role
    };
    let updates = "";

    // can't change status pending -> open
    if (ticket.status === 'Pending' && status === 'Open') {
      setStatusErr(true);
      setUpdateErr('Cannot change status from "Pending" to "Open".');
      return;
    }
    if (ticket.status !== status) {
      data.status = status;
      updates += "Status changed to " + status + ". ";
    }

    // validate num tires
    if (numTireIn && !isNormalInteger(numTireIn)){
      setTireInErr(true);
      return;
    }
    if (ticket.num_tire_in !== numTireIn) {
      data.numTireIn = numTireIn;
      updates += "Tire count (inside) changed to " + numTireIn + ". ";
    }
    if (numTireOut && !isNormalInteger(numTireOut)){
      setTireOutErr(true);
      return;
    }
    if (ticket.num_tire_out !== numTireOut) {
      data.numTireOut = numTireOut;
      updates += "Tire count (outside) changed to " + numTireIn + ". ";
    }

    // check if too many tires to close out
    //if (numTireIn > 50 && status === 'Closed') {
    //  setTireInErr(true);
    //  setUpdateErr('Too many tires remaining to "Close" ticket.');
    //  return;
    //}    
    //if (numTireOut > 50 && status === 'Closed') {
    //  setTireOutErr(true);
    //  setUpdateErr('Too many tires remaining to "Close" ticket.');
    //  return;
    //}

    // validate ETA
    if (ETA && ETA.toString() === 'Invalid Date') {
      setETAErr(true);
      return;
    }
    if (ETA && ticket.eta !== ETA.toISOString()) {
      data.eta = ETA;
      updates += "ETA changed to " + moment(ETA).format('MM-DD-YYYY') + ". ";
    } else if (!ETA && ticket.eta) {
      data.eta = null;
      updates += "ETA removed. ";
    }

    // urgency
    const urgency = ticket.urgency === 'Urgent' ? true : false;
    if (urgency !== isUrgent) {
      data.isUrgent = isUrgent;
      const isUrgentText = isUrgent ? "'Urgent'" : "'Not Urgent'";
      updates += "Urgency changed to " + isUrgentText + ". ";
    }
    
    setIsLoading(true);
    // check store num change
    if (ticket.store !== storeNum.padStart(4, '0')) {
      const store = await API.get("api", "stores/" + storeNum.padStart(4, '0'));
      if (!store) {
        setStoreNumErr(true);
        setIsLoading(false);
        return;
      } else {
        data.store = storeNum.padStart(4, '0');
        data.hauler = store.hauler;
        updates += "Store# changed to " + storeNum.padStart(4, '0') + ". ";
      }
    }

    // note
    if (!updates && !note) {
      setUpdateErr('No changes to submit.');
      setIsLoading(false);
      return;
    }
    data.note = updates + note;

    // update ticket
    API.post("api", "update_ticket/" + ticket.ticket_id, {body: data}).then(() => {
      if (status === 'Closed' && props.cognito.role === 'hauler')
        props.history.push('/create_invoice?store=' + ticket.store)
      else
        props.history.push('/tickets')
    });
  }

  return (
    ticket ? (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Update Pickup Request
          </Typography>
          <Grid container spacing={3}>
            {
              ticket.legacy_id && (
                <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                  <span style={{fontWeight: "600", paddingRight: "3px"}}>
                    Ticket ID (Legacy)
                  </span> {ticket.legacy_id}
                </Grid>
              )
            }
            {
              props.cognito !== null && (props.cognito.role === 'store_manager' || props.cognito.role === 'hauler') && 
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Store#
                </span> {ticket.store}
              </Grid>
            }
            <Grid item xs={12} style={{fontSize: "1.15rem"}}>
              <span style={{fontWeight: "600", paddingRight: "3px"}}>
                Date
              </span> {moment(ticket.date).format('MM-DD-YYYY h:mma')}
            </Grid>
            <Grid item xs={12} style={{fontSize: "1.15rem"}}>
              <span style={{fontWeight: "600", paddingRight: "3px"}}>
                Hauler
              </span> {ticket.hauler}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{marginTop: '10px'}}>
            {
              props.cognito !== null && (props.cognito.role === 'admin' || props.cognito.role === 'account_manager') && 
              <React.Fragment>
              <Grid item xs={3} style={{fontSize: "1.15rem"}}>
                <TextField
                  required
                  label="Store#"
                  error={storeNumErr}
                  value={storeNum}
                  onChange={e => setStoreNum(e.target.value)} 
                  helperText={storeNumErr ? "Invalid Store#" : null}
                  inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                  InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                />
              </Grid>
              <Grid item xs={1} sm={9}>
                <div/>
              </Grid>
              </React.Fragment>
            }
            <Grid item xs={8} sm={3}>
              <FormControl style={{marginTop: "3px"}} className={classes.formControl} error={statusErr}>
                <InputLabel id="demo-simple-select-error-label">Status</InputLabel>
                <Select
                  value={status}
                  onChange={e => {
                    setStatus(e.target.value);
                    if (e.target.value === 'Closed') {
                      setNumTireIn(0);
                      setNumTireOut(0);
                    }
                  }}
                >
                  <MenuItem value={'Open'}>Open</MenuItem>
                  <MenuItem value={'Pending'}>Pending</MenuItem>
                  <MenuItem value={'Closed'}>Closed</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            { props.cognito !== null && (props.cognito.role === 'admin' || props.cognito.role === 'account_manager') &&
              <Grid item xs={4} sm={6}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isUrgent}
                      onChange={() => setIsUrgent(!isUrgent)}
                      color="secondary"
                    />
                  }
                  label="Urgent?"
                  style={{marginTop: "9px"}}
                />
              </Grid>
            }
            <Grid item xs={1} sm={9}>
              <div/>
            </Grid>
            <Grid item xs={8} sm={5}>
              <TextField 
                required
                error={tireInErr}
                onChange={e => setNumTireIn(e.target.value)} 
                value={numTireIn}
                label="Tire count (inside)" 
                inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                helperText="Approx."
                type="number"
              />
            </Grid>
            <Grid item xs={8} sm={5}>
              <TextField 
                required
                error={tireOutErr}
                onChange={e => setNumTireOut(e.target.value)} 
                value={numTireOut}
                label="Tire count (outside)" 
                inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                helperText="Approx."
                type="number"
              />
            </Grid>
            <Grid item xs={10} sm={5}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  error={ETAErr}
                  disableToolbar
                  variant="inline"
                  format="MM-dd-yyyy"
                  label="ETA"
                  inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                  InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
                  value={ETA}
                  onChange={e => setETA(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                  autoOk={true}
                />
              </MuiPickersUtilsProvider>
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Note"
                helperText="Additional update information"
                value={note}
                onChange={e => setNote(e.target.value)} 
                fullWidth
                inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
                InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
              />
            </Grid>
          </Grid>
        </React.Fragment>
        {
          updateErr && (
            <div style={{fontSize: "1.15rem", marginTop: "8px", color: "#f44336"}}>
              {updateErr}
            </div>
          )
        }
        <div style={{display: "flex", "width": "100%"}}>
          <span 
            style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
            onClick={() => {
              if (isLoading) return;
              props.history.goBack();
            }}
          >
            <BackIcon />
            <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
              Back
            </span>
          </span>
          {isLoading ? (
            <CircularProgress size={26} style={{marginLeft: "auto", marginTop: "20px"}} />
          ) : (
            <Button
              style={{marginLeft: "auto", marginTop: "10px"}}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={handleUpdate}
            >
              Submit
            </Button>
          )}
        </div>
      </Paper>
    </Grid>
    ) : ( <Loader isSmall={props.isSmall}/> )
  );
}
