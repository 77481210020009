import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import moment from 'moment';

// libs
import { querystring } from "../../libs/querystring"

// components
import Loader from "../../components/Loader/Loader";
import { Button as Badge } from "../../components/Wrappers/Wrappers";

// styles
import useStyles from "./styles";

const roles = {
  'account_manager': 'Acct Mngr',
  'admin': 'Admin',
  'store_manager': 'Store Mngr',
  'hauler': 'Hauler'
}

export default function Ticket(props) {
  const classes = useStyles();

  //state
  const [ticket, setTicket] = useState(null);
  const [showStore, setShowStore] = useState(false);
  const [store, setStore] = useState(null);

  // badge colors
  const states = {
    Open: "secondary",
    Pending: "info",
    Closed: "primary"
  };

  useEffect(() => {
    // get ticket id from url
    const ticket_id = querystring('ticket_id');
    // fetch ticket from ddb
    API.get('api', 'tickets/' + ticket_id).then(resp => {
      setTicket(resp);
    }).catch(() => console.log('here')); // TODO: handle error
  }, []);

  useEffect(() => {
    if (!showStore) return;
    API.get('api', 'stores/' + ticket.store).then(resp => {
      setStore(resp);
    }).catch(() => console.log('here')); // TODO: handle error
  }, [showStore, ticket]);

  let color;
  if (ticket) {
    if (ticket.urgency === 'Urgent') {
      if (ticket.status === 'Open')
        color = 'warning';
      else if (ticket.status === 'Pending')
        color = 'success';
      else
        color = 'primary';
    } else {
      color = states[ticket.status];
    }
  }
  
  return (
    ticket ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Pickup Request
            </Typography>
            <Grid container spacing={3}>
              {
                ticket.legacy_id && (
                  <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                    <span style={{fontWeight: "600", paddingRight: "3px"}}>
                      Ticket ID (Legacy)
                    </span> {ticket.legacy_id}
                  </Grid>
                )
              }
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <div>
                  <span style={{fontWeight: "600", paddingRight: "3px"}}>
                    Store#
                  </span> {ticket.store}
                  { !showStore && (
                      <span 
                        style={{cursor: 'pointer', fontSize: "1.0rem", fontWeight: "600", paddingLeft: "8px", color: "#FF7F01"}}
                        onClick={() => setShowStore(true)}
                      >
                        Show Store Info
                      </span>
                    )
                  }
                </div>
                { store && (
                    <React.Fragment>
                    <div style={{paddingLeft: "10px"}}>
                      <span style={{fontWeight: "600", paddingRight: "3px"}}>
                        Name
                      </span> {store.store_name}
                    </div>
                    <div style={{paddingLeft: "10px"}}>
                      <span style={{fontWeight: "600", paddingRight: "3px"}}>
                        State
                      </span> {store.state}
                    </div>
                    <div style={{paddingLeft: "10px"}}>
                      <span style={{fontWeight: "600", paddingRight: "3px"}}>
                        Address
                      </span> {store.address}
                    </div>  
                    <div style={{paddingLeft: "10px"}}>
                      <span style={{fontWeight: "600", paddingRight: "3px"}}>
                        Contact
                      </span> {store.contact}
                    </div> 
                    </React.Fragment>
                  )
                }           
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Date
                </span> {moment(ticket.date).format('MM-DD-YYYY h:mma')}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Hauler
                </span> {ticket.hauler}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <div>
                  <span style={{fontWeight: "600", paddingRight: "3px"}}>
                    Approx. tire count (inside)
                  </span> {ticket.num_tire_in ? ticket.num_tire_in : 'unknown'}
                </div>
                <div>
                  <span style={{fontWeight: "600", paddingRight: "3px"}}>
                    Approx. tire count (outside)
                  </span> {ticket.num_tire_out ? ticket.num_tire_out : 'unknown'}
                </div>
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "6px", verticalAlign: '-1px'}}>
                  Status
                </span>
                <Badge
                  color={color}
                  size="small"
                  className="px-2"
                  variant="contained"
                >
                  {ticket.urgency === 'Urgent' ? ticket.status + " (Urgent)" : ticket.status}
                </Badge>
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  ETA
                </span> {ticket.eta ? moment(ticket.eta).format('MM-DD-YYYY') : 'unknown'}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Notes
                </span>
                {
                  ticket.notes.map((note, idx) => (
                    <div key={idx} style={{wordBreak: "break-word", padding: '0px 0px 5px 10px'}}>
                      <span style={{fontWeight: "600"}}>
                        {moment(Object.keys(note)[0].split('Z')[0]+'Z').format('MM-DD-YY h:mma')}
                        {Object.keys(note)[0].split('Z')[1] && roles[Object.keys(note)[0].split('Z')[1]] ?
                          <>&nbsp;({roles[Object.keys(note)[0].split('Z')[1]]})&nbsp;</> : 
                          <>&nbsp;</>}
                      </span>
                      {Object.values(note)[0]}
                    </div>
                  ))
                }
              </Grid>
            </Grid>
          </React.Fragment>
          <div style={{display: "flex", "width": "100%"}}>
            <span 
              style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
              onClick={() => props.history.goBack()} 
            >
              <BackIcon />
              <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
                Back
              </span>
            </span>
            {((props.cognito && props.cognito.role === 'admin') || ticket.status !== 'Closed') && <Button
              style={{marginLeft: "auto", marginTop: "10px"}}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={() => props.history.push('/update_ticket?ticket_id=' + ticket.ticket_id)}
            >
              Update
            </Button>}
          </div>
        </Paper>
      </Grid>
    ) : ( <Loader isSmall={props.isSmall}/> )
  );
}
