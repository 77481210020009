import { makeStyles } from "@material-ui/styles";

export default makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    top: 0,
    left: 0,
    paddingTop: theme.spacing(6)
  },
  paperRoot: {
    boxShadow: "0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  textRow: {
    marginBottom: theme.spacing(3),
    textAlign: "center",
  },
  errorCode: {
    fontSize: 148,
    fontWeight: 600,
  },
  backButton: {
    boxShadow: theme.customShadows.widget,
    textTransform: "none",
    fontSize: '1.25rem',
    color: "white"
  },
  icon: {
    marginLeft: '-8px', 
    marginRight: "5px", 
    fontSize: "30px"
  },
  formButtons: {
    marginTop: theme.spacing(4),
  },
}));
