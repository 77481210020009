import React, { useEffect, useState } from "react";
import { Grid, Paper, Typography, Button } from "@material-ui/core";
import { API } from "aws-amplify";
import { ArrowBackIos as BackIcon } from "@material-ui/icons";

// libs
import { querystring } from "../../libs/querystring"

// components
import Loader from "../../components/Loader/Loader";

// styles
import useStyles from "./styles";

export default function Store(props) {
  const classes = useStyles();

  //state
  const [store, setStore] = useState(null);

  useEffect(() => {
    // get store id from url
    const store_num = querystring('store_num');
    // fetch store from ddb
    API.get('api', 'stores/' + store_num).then(resp => {
      setStore(resp);
    }).catch(() => console.log('here')); // TODO: handle error
  }, []);
  
  return (
    store ? (
      <Grid container className={classes.container}>
        <Paper classes={{ root: classes.paperRoot }}>
          <React.Fragment>
            <Typography variant="h3" gutterBottom>
              Store Info
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Store#
                </span> {store.store_num + " - " + store.store_name}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  State
                </span> {store.state}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Address
                </span> {store.address}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Contact
                </span> {store.contact}
              </Grid>
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Hauler
                </span> {store.hauler}
              </Grid>
            </Grid>
          </React.Fragment>
          <div style={{display: "flex", "width": "100%"}}>
            <span 
              style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
              onClick={() => props.history.goBack()} 
            >
              <BackIcon />
              <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
                Back
              </span>
            </span>
            {<Button
              style={{marginLeft: "auto", marginTop: "10px"}}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={() => props.history.push('/update_store?store_num=' + store.store_num)}
            >
              Update
            </Button>}
          </div>
        </Paper>
      </Grid>
    ) : ( <Loader isSmall={props.isSmall}/> )
  );
}
