import React, { useState, useEffect } from "react";
import Customer from "./Customer";
import { API } from "aws-amplify";
import currency from "currency.js";
import { getCustomerErrors } from "../../libs/Errors";

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring";

export default function Update(props) {
  //state
  const [customer, setCustomer] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  //init
  useEffect(() => {
    const customer_id = querystring("customer_id");

    // get customer data and populate state
    setIsLoading(true);
    API.get("api", "customers/" + customer_id)
      .then((resp) => {
        setCustomer(resp);
        setIsLoading(false);
      })
      .catch(() => console.log("here")); // TODO: handle error
  }, []);

  // Called when the submit button is pressed.
  function handleUpdate(newCustomer) {
    let errorString = getCustomerErrors(newCustomer);

    if (errorString) {
      alert(errorString);
      return;
    }

    // update customer
    API.post("api", "update_customer/" + customer.customer_id, {
      body: newCustomer,
    }).then(() => props.history.push("/customers"));
  }

  return customer && !isLoading ? (
    <Customer
      mode="edit"
      customerData={customer}
      onSubmit={handleUpdate}
      {...props}
    />
  ) : (
    <Loader isSmall={props.isSmall} />
  );
}
