import React, { useState, useEffect } from "react";
import { Grid, Paper, Typography, Button, CircularProgress, FormControl,
  InputLabel,
  MenuItem, Select } from "@material-ui/core";
import TextField from '@material-ui/core/TextField';
import { ArrowBackIos as BackIcon } from "@material-ui/icons";
import { API } from "aws-amplify"; 

// components
import Loader from "../../components/Loader/Loader";

// libs
import { querystring } from "../../libs/querystring"

// styles
import useStyles from "./styles";

export default function Update(props) {
  const classes = useStyles();

  // TODO: put in DDB
  const haulers = [
    "No Hauler",
    "Ben Tire",
    "Black to Green",
    "Emanuel",
    "Best Haulers",
    "Liberty",
    "Liberty Lakin",
    "tyrex"
  ];

  //state
  const [store, setStore] = useState(null);
  const [contact, setContact] = useState("");
  const [hauler, setHauler] = useState("");
  const [contactErr, setContactErr] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // get store id from url
    const store_num = querystring('store_num');
    // fetch store from ddb
    API.get('api', 'stores/' + store_num).then(resp => {
      setStore(resp);
      setContact(resp.contact);
      setHauler(resp.hauler);
    }).catch(() => console.log('here')); // TODO: handle error
  }, []);

  function handleUpdate() {
    // reset form errors
    setContactErr(false);

    if (!contact) {
      setContactErr(true);
      return;
    }

    // update store
    setIsLoading(true);
    API.post("api", "update_store/" + store.store_num, {
      body: { contact: contact, hauler: hauler }
    }).then(() => props.history.push('/stores'));
  }

  return (
    store ? (
    <Grid container className={classes.container}>
      <Paper classes={{ root: classes.paperRoot }}>
        <React.Fragment>
          <Typography variant="h3" gutterBottom>
            Store Info
          </Typography>
          <Grid container spacing={3}>
            <Grid item xs={12} style={{fontSize: "1.15rem"}}>
              <span style={{fontWeight: "600", paddingRight: "3px"}}>
                Store#
              </span> {store.store_num + " - " + store.store_name}
            </Grid>
            <Grid item xs={12} style={{fontSize: "1.15rem"}}>
              <span style={{fontWeight: "600", paddingRight: "3px"}}>
                State
              </span> {store.state}
            </Grid>
            <Grid item xs={12} style={{fontSize: "1.15rem"}}>
              <span style={{fontWeight: "600", paddingRight: "3px"}}>
                Address
              </span> {store.address}
            </Grid>
            { props.cognito !== null && props.cognito.role !== 'admin' &&
              <Grid item xs={12} style={{fontSize: "1.15rem"}}>
                <span style={{fontWeight: "600", paddingRight: "3px"}}>
                  Hauler
                </span> {store.hauler}
              </Grid>
            }
          </Grid>
        </React.Fragment>
        <Grid container spacing={2} style={{marginTop: '20px'}}>
          { props.cognito !== null && props.cognito.role === 'admin' && 
            <Grid item xs={7} sm={5}>
              <FormControl 
                style={{minWidth: 140}} 
                className={classes.formControl}
              >
                <InputLabel>Hauler</InputLabel>
                <Select
                  value={hauler}
                  onChange={e => setHauler(e.target.value)}
                >
                  {
                    haulers.map(hauler => (
                      <MenuItem key={hauler} value={hauler}>{hauler}</MenuItem>
                    ))
                  }
                </Select>
              </FormControl>
            </Grid>
          }
          <Grid item xs={7} sm={5}>
            <TextField 
              required
              error={contactErr}
              onChange={e => setContact(e.target.value)} 
              value={contact}
              label="Contact" 
              inputProps={{style: {fontSize: '1.15rem'}}} // font size of input text
              InputLabelProps={{style: {fontSize: '1.15rem'}}} // font size of input label
            />
          </Grid>
        </Grid>
        <div style={{display: "flex", "width": "100%"}}>
          <span 
            style={{marginTop: "19px", cursor: "pointer", color: "#FF7F01"}} 
            onClick={() => {
              if (isLoading) return;
              props.history.goBack();
            }}
          >
            <BackIcon />
            <span style={{verticalAlign: "6px", fontSize: "1.1rem"}}>
              Back
            </span>
          </span>
          {isLoading ? (
            <CircularProgress size={26} style={{marginLeft: "auto", marginTop: "20px"}} />
          ) : (
            <Button
              style={{marginLeft: "auto", marginTop: "10px"}}
              variant="contained"
              color="secondary"
              size="large"
              className={classes.backButton}
              onClick={handleUpdate}
            >
              Submit
            </Button>
          )}
        </div>
      </Paper>
    </Grid>
    ) : ( <Loader isSmall={props.isSmall}/> )
  );
}
