import React, { useState, useEffect } from "react";
import { Grid } from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { Add as AddIcon, NoteAdd as SummaryIcon  } from "@material-ui/icons";
import { API } from "aws-amplify";
import {
  createTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import moment from 'moment';

// styles
import useStyles from "./styles";

// components
import PageTitle from "../../components/PageTitle/PageTitle";
import Loader from "../../components/Loader/Loader";
import { Button } from "../../components/Wrappers/Wrappers";

export default function Tickets(props) {
  const classes = useStyles();
  function getMuiTheme() {
    return createTheme({
      overrides: {
        MuiTableRow: {
          hover: {
            '&$root': {
              '&:hover': {
                cursor: 'pointer', // make row's look clickable
                backgroundColor: 'rgba(74, 74, 74, 0.08)' // force bg color
              },
            }
          },
        },
        MuiIconButton: {
          root: {
            color: '#116466 !important', // primary color
          }
        },
        MUIDataTableHeadCell: { // force column width
          root: {
            '&:nth-child(1)': {
              width: 150
            },
            '&:nth-child(2)': {
              width: 150
            },
            '&:nth-child(3)': {
              width: 190
            },
            '&:nth-child(4)': {
              width: 200
            },
            '&:nth-child(5)': {
              width: 200
            }
          }
        }
      }
    });
  }

  // state
  const [tickets, setTickets] = useState(null); // raw data
  const [ticketsTable, setTicketsTable] = useState([]); // data for mui table

  // data table settings
  const states = {
    Open: "secondary",
    Pending: "info",
    Closed: "primary"
  };

  const columns = [
    {
      label: "Date",
      name: "Date",
      options: {    
        filter: false,
        customBodyRender: date => (
          <React.Fragment>
            {moment(date).format('MM-DD-YYYY')}
          </React.Fragment>
        )
      },
    }, 
    {
      label: "Store#",
      name: "Store#",
      options: {
        filter: false
      },
    }, 
    {
      label: "Hauler",
      name: "Hauler",
      options: {    
        filterList: Array.isArray(props.filters.tickets) ? props.filters.tickets[2] : null
      },
    }, 
    {
      label: "Status",
      name: "Status",
      options: {    
        customBodyRender: (status, tableMeta) => {
          const urgency = tableMeta.rowData[4];
          let color;
          if (urgency === 'Urgent') {
            if (status === 'Open')
              color = 'warning';
            else if (status === 'Pending')
              color = 'success';
            else
              color = 'primary';
          } else 
            color = states[status];
          return (
            <Button
              color={color}
              size="small"
              className="px-2"
              variant="contained"
            >
              {urgency === 'Urgent' ? status + " (Urgent)" : status}
            </Button>
          );
        },
        filterList: Array.isArray(props.filters.tickets) ? props.filters.tickets[3] : null
      },
    },
    {
      label: "Urgency",
      name: "Urgency",
      options: {
        display: false, 
        viewColumns: false,
        filterList: Array.isArray(props.filters.tickets) ? props.filters.tickets[4] : null
      },
    }, 
    {
      label: "Ticket ID (Legacy)",
      name: "Ticket ID (Legacy)",
      options: {    
        filter: false
      },
    }, 
    {
      label: "ETA",
      name: "ETA",
      options: {    
        filter: false,
        customBodyRender: date => (
          date ? (
            <React.Fragment>
              {moment(date).format('MM-DD-YYYY')}
            </React.Fragment>
          ) : null
        )
      },
    }, 
  ];

  const options = {
    filter: true,
    responsive: 'vertical',
    sortOrder: {
      name: 'Date',
      direction: 'desc'
    },
    rowsPerPage: 10,
    rowsPerPageOptions: [10, 25, 50],
    filterType: "checkbox",
    selectableRows: "none",
    onRowClick: (row,index) => {
      // get ticket_id from data (based on index)
      const ticket_id = tickets[index.dataIndex].ticket_id;
      props.history.push('/ticket?ticket_id=' + ticket_id);
    },
    onFilterChange: (columnChanged, filterList) => {
      // update app props with new filters
      props.setFilters(Object.assign(props.filters, {tickets: filterList}));
    }
  };

  // init
  useEffect(() => {
    if (!props.cognito) return; // wait for cognito

    let params = {};
    if (props.cognito.role === 'store_manager')
      params = {queryStringParameters: {store_num: props.cognito.role_details}};
    else if (props.cognito.role === 'hauler')
      params = {queryStringParameters: {hauler: props.cognito.role_details}};
    
    // fetch tickets from ddb
    API.get('api', 'tickets', params).then(resp => {
      setTickets(resp);
      // build array for mui table
      const ticketsTable = resp.map(ticket => {
        return [
          ticket.date,
          ticket.store,
          ticket.hauler,
          ticket.status, 
          ticket.urgency,
          ticket.legacy_id,
          ticket.eta
        ];
      });
      setTicketsTable(ticketsTable);
    });
  }, [props.cognito]);

  const buttons = [];

  if (props.cognito && props.cognito.role !== 'hauler')
    buttons.push({
      text: "Create Ticket",
      icon: <AddIcon className={classes.icon} />,
      func: () => props.history.push('/create_ticket')
    });
  if (props.cognito && props.cognito.role !== 'store_manager')
    buttons.unshift({
      text: "Generate Report",
      icon: <SummaryIcon className={classes.icon} />,
      func: () => props.history.push('/ticket_report')
    });

  return (
    <React.Fragment>
      <PageTitle 
        title="Tickets" 
        buttons={buttons.length ? buttons : null}
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          {tickets === null ? <Loader isSmall={props.isSmall}/> : null}
          <MuiThemeProvider theme={getMuiTheme()}>
            <MUIDataTable
              title="Pickup Requests"
              data={ticketsTable}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
        </Grid>
      </Grid>
    </React.Fragment>
  );
}
